import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'languageAbbr'
})
export class LanguageAbbrPipe implements PipeTransform {
    transform(languageName: string): string {
        return languageName.substring(0, 2).toUpperCase();
    }
}
