<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Maintenance' | localize">
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-body">
                    <div class="row m-3">
                        <div class="col-xs-12">
                            <div [innerHtml]="l('MaintenancePageContent')"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
