import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { VisibilityServiceProxy, GetVisibilityOutput } from '@shared/service-proxies/service-proxies';
import { ApplicationService } from '@app/shared/application/application.service';

// do NOT include service in @NgModule.providers of any module to use as singleton in lazy loaded modules
// see https://indepth.dev/how-to-avoid-angular-injectable-instances-duplication/
@Injectable({ providedIn: 'root' })
export class VisibilityService {

    private visibilityReplaySubject = new ReplaySubject<GetVisibilityOutput>(1);

    constructor(
        private visibilityService: VisibilityServiceProxy,
        private applicationService: ApplicationService) {
    }

    update(): Observable<GetVisibilityOutput> {
        const visibilitySubject = new Subject<GetVisibilityOutput>();
        this.visibilityService.getVisibility(this.applicationService.type, this.applicationService.caseId).pipe(first()).subscribe((result) => {
            visibilitySubject.next(result);
            this.visibilityReplaySubject.next(result);
            visibilitySubject.complete();
        });

        return visibilitySubject.asObservable();
    }

    changes(): Observable<GetVisibilityOutput> {
        return this.visibilityReplaySubject.asObservable();
    }
}
