import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.less']
})
export class MaintenancePageComponent extends AppComponentBase {

    constructor(
        injector: Injector) {
        super(injector);
    }


}
