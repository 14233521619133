import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DlaService } from './dla.service';

@Injectable()
export class DlaPersonGuard implements CanActivate {

    constructor(
        private dlaService: DlaService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const personId = route.paramMap.get('personId');
        return of(this.dlaService.checkIfPersonExists(Number(personId)));
    }
}
