import {
    EhcDto,
    EhcServiceProxy,
    EhcStatus,
    GetEhcOutput,
    EhcSelfEmploymentServiceProxy,
    CreateOrUpdateEhcSelfEmploymentInput,
    EhcSelfEmploymentDto,
    EhcMedicalFieldDto,
    CreateOrUpdateEhcMedicalFieldInput,
    EhcSearchDto,
    EhcMedicalFieldServiceProxy,
    MedRegWbtDto,
    EhcLanguageSkillServiceProxy,
    EhcPracticeServiceProxy,
    EhcElectronicPatientRecordServiceProxy,
    EhcContactServiceProxy,
    EhcEmploymentServiceProxy,
    EhcInvoiceAddressServiceProxy,
    EhcLanguageSkillDto,
    CreateOrUpdateEhcLanguageSkillInput,
    EhcDocumentsOutput,
    EhcPracticeDto,
    CreateOrUpdateEhcPracticesInput,
    CreateOrUpdateEhcPracticesOutput,
    EhcElectronicPatientRecordDto,
    CreateOrUpdateEhcElectronicPatientRecordInput,
    EhcQualityRequirementServiceProxy,
    EhcQualityRequirementDto,
    CreateOrUpdateEhcQualityRequirementInput,
    EhcContactDto,
    CreateOrUpdateEhcContactInput,
    CreateOrUpdateEhcEmploymentsInput,
    CreateOrUpdateEhcEmploymentsOutput,
    CreateOrUpdateEhcInvoiceAddressInput,
    EhcInvoiceAddressDto,
    EhcEmploymentDto,
    EhcReleaseCombinedInput,
    EhcEmploymentOutput,
    LiaEmploymentReadOnlyOutput,
    ProfessionRequirementType,
    EhcRegisterDto,
    EhcRegisterServiceProxy,
    CreateOrUpdateEhcRegister,
    CreateOrUpdateEhcRegisterOutputDto,
    MySironaEmploymentReadOnlyOutput,
    CreateOrUpdateEhcLiaEmploymentLinksInput,
    MySironaEmploymentReadOnlyDto,
    EhcEducationServiceProxy,
    EhcReleaseCombinedOutput,
} from './../../../shared/service-proxies/service-proxies';
import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { tap, map, delay, switchMap } from 'rxjs/operators';
import {
    ApplicationUploadFilesInput,
    ApplicationServiceProxy,
    GeneralFileInfoDto,
    GeneralFileDto,
    GeneralDocumentGroupDto,
    LiaContactDto,
} from '@shared/service-proxies/service-proxies';
import { SelectItem } from 'primeng/api';
import { AppConsts } from '@shared/AppConsts';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from './profession-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class EhcService extends ApplicationService {
    ehc: EhcDto;
    ehcResultDocuments: GeneralFileInfoDto[];
    onlyPrivateAddressAllowed = false;

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private professionConfigurationService: ProfessionConfigurationService,
        private ehcService: EhcServiceProxy,
        private ehcSelfEmploymentService: EhcSelfEmploymentServiceProxy,
        private ehcRegisterService: EhcRegisterServiceProxy,
        private ehcEducationService: EhcEducationServiceProxy,
        private ehcMedicalFieldService: EhcMedicalFieldServiceProxy,
        private ehcLanguageSkillService: EhcLanguageSkillServiceProxy,
        private ehcPracticeService: EhcPracticeServiceProxy,
        private ehcElectronicPatientRecordService: EhcElectronicPatientRecordServiceProxy,
        private ehcQualityRequirementService: EhcQualityRequirementServiceProxy,
        private ehcContactService: EhcContactServiceProxy,
        private ehcEmploymentService: EhcEmploymentServiceProxy,
        private ehcInvoiceAddressService: EhcInvoiceAddressServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    get hasLiaReference(): boolean {
        return this.ehc.hasLiaReference;
    }

    get hasAdditionallyLinkedEhcApplications(): boolean {
        return this.ehc.numberOfAdditionalLinkedApplications > 0;
    }

    get caseId(): string {
        return this.ehc.application.caseId;
    }

    get profession(): string {
        return this.ehc.professionId;
    }

    get liaCaseId(): string {
        return this.ehc.liaCaseId;
    }

    get hasLiaApplicationInStatusWaiting(): boolean {
        return this.ehc.hasLiaApplicationInWaitingStatus;
    }

    get firstStep(): EhcStatus {
        return this.ehc.firstStep;
    }

    get currentStep(): EhcStatus {
        return this.ehc.currentStep;
    }

    get medRegId(): number {
        return this.ehc.ehcRegister.medRegId;
    }

    get hasMedRegId(): boolean {
        return this.ehc.ehcRegister?.medRegId != null;
    }

    get psyRegId(): number {
        return this.ehc.ehcRegister.psyRegId;
    }

    get hasPsyRegId(): boolean {
        return this.ehc.ehcRegister?.psyRegId != null;
    }

    get naregId(): number {
        return this.ehc.ehcRegister.naregId;
    }

    get hasNaregId(): boolean {
        return this.ehc.ehcRegister?.naregId != null;
    }

    canCreateAdditonalLinkedApplication(): Observable<boolean> {
        return this.ehcService.canCreateAdditionalLinkedApplication(this._caseId);
    }

    createOrUpdateEhcRegister(input: CreateOrUpdateEhcRegister): Observable<CreateOrUpdateEhcRegisterOutputDto> {
        this.setStepToReview(EhcStatus.Register);
        return this.ehcRegisterService.createOrUpdateEhcRegister(input).pipe(
            tap((output: CreateOrUpdateEhcRegisterOutputDto) => {
                this.ehc.ehcRegister = output.ehcRegister;
            })
        );
    }

    setEhcRegisterStepToReview() {
        this.setStepToReview(EhcStatus.Register);
    }

    acceptRegisterCheck(): Observable<EhcStatus> {
        this.setStepToReview(EhcStatus.Register);
        return this.ehcRegisterService.acceptRegisterCheck(this.caseId).pipe(
            tap((status: EhcStatus) => {
                this.ehc.currentStep = status;
            })
        );
    }

    hasWaitingLiaApplication(): Observable<boolean> {
        return this.ehcService.hasWaitingLiaApplication(this._caseId);
    }

    isOnlyPrivateAddressAllowed(): boolean {
        return this.onlyPrivateAddressAllowed;
    }

    getEhc(caseId: string): Observable<GetEhcOutput> {
        this.ehc = null;
        return this.getApplication(caseId).pipe(
            switchMap(() => {
                return this.ehcService.getEhc(caseId).pipe(
                    tap((output: GetEhcOutput) => {
                        this.ehc = output.ehc;

                        return this.professionConfigurationService
                            .getRequirementByProfessionAndType(
                                this.ehc.professionId,
                                ProfessionRequirementType.OnlyPrivateAddressAllowed
                            )
                            .subscribe((result) => {
                                this.onlyPrivateAddressAllowed = result.value == this.codes.yesNo.yes;
                            });
                    })
                );
            })
        );
    }

    getMyEhcs(): Observable<EhcDto[]> {
        return this.ehcService.getMyEhcs();
    }

    getEhcRegister(): Observable<EhcRegisterDto> {
        return of(this.ehc.ehcRegister).pipe(delay(1));
    }

    checkDocuments(step: EhcStatus): boolean {
        switch (step) {
            case EhcStatus.Practice:
                return this.checkEhcPracticeDocuments();
            case EhcStatus.LanguageSkill:
                if (this.ehc.ehcLanguageSkill.hasPhysicianDiplomaOfLanguageOfActivityRegionId == this.codes.yesNo.no) {
                    return this.areAllMandatoryDocumentsUploaded(this.ehc.ehcLanguageSkill.languageSkillDocuments);
                } else {
                    return true;
                }
            default:
                return true;
        }
    }

    loadMissingDocumentGroups(step: EhcStatus): Observable<void> {
        switch (step) {
            case EhcStatus.Practice:
                if (this.ehc.ehcPractice.ehcPracticeRecordDocuments == null) {
                    return this.getEhcPracticeRecordFiles(true).pipe(
                        map((docs) => {
                            this.ehc.ehcPractice.ehcPracticeRecordDocuments = docs;
                        })
                    );
                }
                break;
            case EhcStatus.LanguageSkill:
                if (this.ehc.ehcLanguageSkill.languageSkillDocuments == null) {
                    return this.getLanguageSkillFiles(true).pipe(
                        map((docs) => {
                            this.ehc.ehcLanguageSkill.languageSkillDocuments = docs;
                        })
                    );
                }
                break;
        }

        // If there is no asynchronous operation to perform, return an empty observable
        return of(undefined);
    }

    checkEhcPracticeDocuments() {
        if (this.ehc.ehcPractice.exemptionAccordingArt37Kvg === true) {
            return true;
        }

        const documents = this.ehc.ehcPractice.ehcPracticeRecordDocuments;
        if (documents == null) {
            return false;
        }
        const practiceRecordCount = this.ehc.ehcPractice.ehcPracticeRecords.length;
        const fileGroup = documents.filter(
            (document) => document.typeId === AppConsts.codes.documentType.proofOfPractice
        );
        const proofOfPracticeDocuments = fileGroup[0].files;
        return proofOfPracticeDocuments.length >= practiceRecordCount;
    }

    getEvaluationComments(): Observable<string> {
        return this.ehcService.getEvaluationComments(this._caseId);
    }

    getNextStatus(status: EhcStatus): Observable<EhcStatus> {
        return this.ehcService.getNextStatus(this._caseId, status);
    }

    getSelfEmployment(): Observable<EhcSelfEmploymentDto> {
        return of(this.ehc.ehcSelfEmployment).pipe(delay(0));
    }

    getPostgraduateTitles(): Observable<SelectItem[]> {
        return this.ehcMedicalFieldService.getPostgraduateTitles(this.caseId).pipe(
            map((items: MedRegWbtDto[]) => {
                return items.map((item: MedRegWbtDto) => {
                    return {
                        label: item.art.sironaId,
                        value: item.art.sironaId,
                    };
                });
            })
        );
    }

    createOrUpdateEhcSelfEmployment(input: CreateOrUpdateEhcSelfEmploymentInput): Observable<void> {
        this.setStepToReview(EhcStatus.SelfEmployment);
        return this.ehcSelfEmploymentService
            .createOrUpdateEhcSelfEmployment(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcSelfEmployment = input.ehcSelfEmployment;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    getEhcMedicalField(): Observable<EhcMedicalFieldDto> {
        return of(this.ehc.ehcMedicalField).pipe(delay(0));
    }

    getEducationFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.ehcEducationService
                .getEhcEducationDocuments(caseId)
                .pipe(
                    tap((output: EhcDocumentsOutput) => {
                        this.ehc.ehcEducationOutput.documents = output.ehcDocuments;
                    })
                )
                .pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
        }
        return of(this.ehc.ehcLanguageSkill.languageSkillDocuments);
    }

    getEducationFile(fileId: number): Observable<GeneralFileDto> {
        return this.ehcEducationService.getFile(this._caseId, fileId);
    }

    uploadEducationFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.ehcEducationService.uploadFiles(input);
    }

    deleteEducationFile(fileId: number): Observable<void> {
        return this.ehcEducationService.deleteFile(this._caseId, fileId);
    }

    ehcEducationProceed(): Observable<void> {
        this.setStepToReview(EhcStatus.Education);
        return this.ehcEducationService
            .proceed(this.caseId)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map(() => null));
    }

    createOrUpdateEhcMedicalField(input: CreateOrUpdateEhcMedicalFieldInput): Observable<void> {
        this.setStepToReview(EhcStatus.MedicalField);
        return this.ehcMedicalFieldService
            .createOrUpdateEhcMedicalField(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcMedicalField = input.ehcMedicalField;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    getEhcLanguageSkill(): Observable<EhcLanguageSkillDto> {
        return of(this.ehc.ehcLanguageSkill).pipe(delay(0));
    }

    createOrUpdateEhcLanguageSkill(input: CreateOrUpdateEhcLanguageSkillInput): Observable<void> {
        this.setStepToReview(EhcStatus.LanguageSkill);
        return this.ehcLanguageSkillService
            .createOrUpdateEhcLanguageSkill(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcLanguageSkill = input.ehcLanguageSkill;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    getLanguageSkillFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.ehcLanguageSkillService
                .getEhcLanguageSkillDocuments(caseId)
                .pipe(
                    tap((output: EhcDocumentsOutput) => {
                        this.ehc.ehcLanguageSkill.languageSkillDocuments = output.ehcDocuments;
                    })
                )
                .pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
        }
        return of(this.ehc.ehcLanguageSkill.languageSkillDocuments);
    }

    getLanguageSkillFile(fileId: number): Observable<GeneralFileDto> {
        return this.ehcLanguageSkillService.getFile(this._caseId, fileId);
    }

    uploadLanguageSkillFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.ehcLanguageSkillService.uploadFiles(input);
    }

    deleteLanguageSkillFile(fileId: number): Observable<void> {
        return this.ehcLanguageSkillService.deleteFile(this._caseId, fileId);
    }

    getEhcPractice(): Observable<EhcPracticeDto> {
        return this.ehcPracticeService.getEhcPractice(this.caseId);
    }

    createBlankEhcPracticeRecord(): Observable<string> {
        return this.ehcPracticeService.createBlankEhcPracticeRecord(this.caseId);
    }

    createOrUpdateEhcPractices(input: CreateOrUpdateEhcPracticesInput): Observable<void> {
        this.setStepToReview(EhcStatus.Practice);
        return this.ehcPracticeService
            .createOrUpdateEhcPractices(input)
            .pipe(
                tap((output: CreateOrUpdateEhcPracticesOutput) => {
                    this.ehc.ehcPractice = input.ehcPractice;
                    this.ehc.ehcPractice.ehcPracticeConfigSnapshot = output.ehcPractice.ehcPracticeConfigSnapshot;
                    this.ehc.currentStep = output.status;
                })
            )
            .pipe(map((output: CreateOrUpdateEhcPracticesOutput) => null));
    }

    deleteEhcPracticeRecord(guid: string): Observable<void> {
        return this.ehcPracticeService.deleteEhcPracticeRecord(this.caseId, guid);
    }

    getEhcPracticeRecordFiles(
        forceRefresh?: boolean,
        caseId = this._caseId,
        hasExemptionAccordingArt37Kvg: boolean | undefined = undefined
    ): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            if (
                this.ehc.ehcPractice.exemptionAccordingArt37Kvg === true &&
                hasExemptionAccordingArt37Kvg === undefined
            ) {
                hasExemptionAccordingArt37Kvg = true;
            }
            return this.ehcPracticeService
                .getEhcPracticeRecordFiles(caseId, hasExemptionAccordingArt37Kvg)
                .pipe(
                    tap((output: EhcDocumentsOutput) => {
                        this.ehc.ehcPractice.ehcPracticeRecordDocuments = output.ehcDocuments;
                    })
                )
                .pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
        }
        return of(this.ehc.ehcPractice.ehcPracticeRecordDocuments);
    }

    getEhcPracticeRecordFile(fileId: number): Observable<GeneralFileDto> {
        return this.ehcPracticeService.getFile(this._caseId, fileId);
    }

    uploadEhcPracticeRecordFiles(
        ehcPracticeRecordId: string,
        input: ApplicationUploadFilesInput
    ): Observable<GeneralFileInfoDto[]> {
        return this.ehcPracticeService.uploadFiles(ehcPracticeRecordId, input);
    }

    deleteEhcPracticeRecordFile(fileId: number): Observable<void> {
        return this.ehcPracticeService.deleteFile(this._caseId, fileId);
    }

    getEhcElectronicPatientRecord(): Observable<EhcElectronicPatientRecordDto> {
        return of(this.ehc.ehcElectronicPatientRecord).pipe(delay(0));
    }

    createOrUpdateEhcElectronicPatientRecord(input: CreateOrUpdateEhcElectronicPatientRecordInput): Observable<void> {
        this.setStepToReview(EhcStatus.ElectronicPatientRecord);
        return this.ehcElectronicPatientRecordService
            .createOrUpdateEhcElectronicPatientRecord(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcElectronicPatientRecord = input.ehcElectronicPatientRecord;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    getEhcQualityRequirement(): Observable<EhcQualityRequirementDto> {
        return of(this.ehc.ehcQualityRequirement).pipe(delay(0));
    }

    createOrUpdateEhcQualityRequirement(input: CreateOrUpdateEhcQualityRequirementInput): Observable<void> {
        this.setStepToReview(EhcStatus.QualityRequirement);
        return this.ehcQualityRequirementService
            .createOrUpdateEhcQualityRequirement(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcQualityRequirement = input.ehcQualityRequirement;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    getEhcContact(): Observable<EhcContactDto> {
        return of(this.ehc.ehcContact).pipe(delay(0));
    }

    createOrUpdateEhcContact(input: CreateOrUpdateEhcContactInput): Observable<void> {
        this.setStepToReview(EhcStatus.Contact);
        return this.ehcContactService
            .createOrUpdateEhcContact(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcContact = input.ehcContact;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    proceedEhcContact(): Observable<void> {
        return this.ehcContactService
            .proceedEhcContact(this.caseId)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    hasNecessaryEducation(): Observable<boolean> {
        return this.ehcEducationService.hasNecessaryNaregEducation(this._caseId, this.ehc.professionId);
    }

    getEmployments(): Observable<EhcEmploymentOutput> {
        return of(this.ehc.ehcEmployments).pipe(delay(0));
    }

    getLastSupplementalClaim(): Observable<string> {
        return this.ehcService.getLastSupplementalClaim(this._caseId);
    }

    createOrUpdateEhcEmploymentLinks(input: CreateOrUpdateEhcLiaEmploymentLinksInput): Observable<void> {
        return this.ehcEmploymentService
            .createOrUpdateEhcEmploymentLinks(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    createOrUpdateEhcEmployments(input: CreateOrUpdateEhcEmploymentsInput): Observable<void> {
        this.setStepToReview(EhcStatus.Employment);
        return this.ehcEmploymentService
            .createOrUpdateEhcEmployments(input)
            .pipe(
                tap((output: CreateOrUpdateEhcEmploymentsOutput) => {
                    this.ehc.ehcEmployments.employments = output.ehcEmployments;
                    this.ehc.ehcEmployments.mobileEmployment = output.ehcMobileEmployment;
                    this.ehc.currentStep = output.status;
                })
            )
            .pipe(map((output: CreateOrUpdateEhcEmploymentsOutput) => null));
    }

    getLiaContactReadOnly(caseId: string): Observable<LiaContactDto> {
        return this.ehcContactService.getLiaContactReadOnly(caseId);
    }

    getLiaEmploymentsAndLinksReadOnly(caseId: string, ehcCaseId: string): Observable<LiaEmploymentReadOnlyOutput> {
        return this.ehcEmploymentService.getLiaEmploymentsReadOnlyWithLinks(caseId, ehcCaseId);
    }

    getMySironaEmploymentAndLinksReadOnly(caseId: string): Observable<MySironaEmploymentReadOnlyOutput> {
        return this.ehcEmploymentService.getMySironaEmploymentsReadOnlyWithLinks(caseId);
    }

    getInvoiceAddressForEhc(
        caseId: string,
        ehcCaseId: string
    ): Observable<[LiaContactDto, LiaEmploymentReadOnlyOutput]> {
        return combineLatest([
            this.getLiaContactReadOnly(caseId),
            this.getLiaEmploymentsAndLinksReadOnly(caseId, ehcCaseId),
        ]);
    }

    getMySironaEmploymentsForInvoiceAddress(caseId: string): Observable<MySironaEmploymentReadOnlyOutput> {
        return this.ehcEmploymentService.getMySironaEmploymentsReadOnlyWithLinks(caseId);
    }

    getEhcInvoiceAddress(): Observable<EhcInvoiceAddressDto> {
        return of(this.ehc.ehcInvoiceAddress).pipe(delay(0));
    }

    createOrUpdateEhcInvoiceAddress(input: CreateOrUpdateEhcInvoiceAddressInput): Observable<void> {
        this.setStepToReview(EhcStatus.InvoiceAddress);
        return this.ehcInvoiceAddressService
            .createOrUpdateEhcInvoiceAddress(input)
            .pipe(
                tap((status: EhcStatus) => {
                    this.ehc.ehcInvoiceAddress = input.ehcInvoiceAddress;
                    this.ehc.currentStep = status;
                })
            )
            .pipe(map((status: EhcStatus) => null));
    }

    createAddressFromMarkedEmploymentsDropdownList(onlyPrivateAddressAllowed: boolean): SelectItem[] {
        let addresses: SelectItem[] = [];

        const allNull = Object.values(this.ehc.ehcContact).every((value) => {
            if (value === null) {
                return true;
            }
        });

        if (!allNull) {
            addresses.push({
                value: AppConsts.codes.address.private,
                label: this.l('Address-Private') + ': ' + this.getPrivateAddressDescription(this.ehc.ehcContact),
            });
        }

        if (onlyPrivateAddressAllowed) {
            return addresses;
        }

        for (let i = 0; i < this.ehc.ehcEmployments.employments.length; i++) {
            if (this.ehc.ehcEmployments.employments[i].isMarkedForEhc) {
                addresses.push({
                    value: this.ehc.ehcEmployments.employments[i].id,
                    label:
                        this.l('EmploymentSingular') +
                        ': ' +
                        this.getEmploymentAddressDescription(this.ehc.ehcEmployments.employments[i]),
                });
            }
        }

        if (addresses.length > 0) {
            addresses.push({ value: AppConsts.codes.address.invoice, label: this.l(AppConsts.codes.address.invoice) });
        }

        return addresses;
    }

    createAddressDropdownList(onlyPrivateAddressAllowed: boolean): SelectItem[] {
        let addresses: SelectItem[] = [];

        const allNull = Object.values(this.ehc.ehcContact).every((value) => {
            if (value === null) {
                return true;
            }
        });

        if (!allNull) {
            addresses.push({
                value: AppConsts.codes.address.private,
                label: this.l('Address-Private') + ': ' + this.getPrivateAddressDescription(this.ehc.ehcContact),
            });
        }

        if (onlyPrivateAddressAllowed) {
            return addresses;
        }

        for (let i = 0; i < this.ehc.ehcEmployments.employments.length; i++) {
            // Use employment id as item value
            addresses.push({
                value: this.ehc.ehcEmployments.employments[i].id,
                label:
                    this.l('EmploymentSingular') +
                    ': ' +
                    this.getEmploymentAddressDescription(this.ehc.ehcEmployments.employments[i]),
            });
        }

        if (addresses.length > 0) {
            addresses.push({ value: AppConsts.codes.address.invoice, label: this.l(AppConsts.codes.address.invoice) });
        }

        return addresses;
    }

    releaseEhcCombined(input: EhcReleaseCombinedInput): Observable<EhcReleaseCombinedOutput> {
        return this.ehcService.releaseEhcCombined(input);
    }

    releaseEhc(): Observable<void> {
        return this.ehcService.releaseEhc(this._caseId);
    }

    getStepUrl(nextStep: EhcStatus): string {
        return this.getNextStep(nextStep);
    }

    getNextStep(nextStep: EhcStatus): string {
        const baseUrl = AppConsts.applicantBaseUrl + `/ehc/${this.caseId}/wizard/`;
        const path = this.getUrlForStep(nextStep);
        return baseUrl + path;
    }

    isInEhcStatus(expectedStatus: EhcStatus): boolean {
        return this.ehc.currentStep === expectedStatus;
    }

    withdrawEhcAndReleaseLia(): Observable<void> {
        return this.ehcService.withdrawEhcAndReleaseLia(this._caseId, this.liaCaseId);
    }

    withdrawEhcAndLia(): Observable<void> {
        return this.ehcService.withdrawEhcAndLia(this._caseId, this.liaCaseId);
    }

    isEmploymentEmailAddressRedundant(employments: EhcEmploymentDto[]): boolean {
        const email = this.ehc?.ehcContact?.email;
        const userEmail = this.ehc?.application?.userEmail;
        for (let i = 0; i < employments.length; i++) {
            if (employments[i].email === email || employments[i].email === userEmail) {
                return true;
            }
        }
        return false;
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case EhcStatus.SelfEmployment:
                return 'self-employment';
            case EhcStatus.Register:
                return 'register';
            case EhcStatus.Education:
                return 'education';
            case EhcStatus.MedicalField:
                return 'medical-field';
            case EhcStatus.LanguageSkill:
                return 'language-skills';
            case EhcStatus.Practice:
                return 'practice';
            case EhcStatus.ElectronicPatientRecord:
                return 'electronic-patient-record';
            case EhcStatus.QualityRequirement:
                return 'quality-requirement';
            case EhcStatus.Contact:
                return 'contact';
            case EhcStatus.Employment:
                return 'employment';
            case EhcStatus.InvoiceAddress:
                return 'invoice-address';
            case EhcStatus.Release:
                return 'release';
            case EhcStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }

    private getPrivateAddressDescription(contact: EhcContactDto) {
        let str = contact?.street;

        if (contact?.streetNr && contact.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(contact.streetNr);
        }

        str = str?.concat(', ');
        str = str?.concat(contact?.city);

        return str;
    }

    private getEmploymentAddressDescription(employment: EhcEmploymentDto) {
        let str = employment?.businessName;

        if (employment.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.street);

            if (employment.streetNr?.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.streetNr);
            }
        }

        if (employment.city?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.city);
        }

        return str;
    }
}
