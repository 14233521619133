import { Injectable, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
    DlaDto,
    ApplicationServiceProxy,
    DlaServiceProxy,
    GetDlaOutput,
    DlaStatus,
    CreateOrUpdateDlaContactInput,
    CreateOrUpdateDlaDaycareInput,
    CreateOrUpdateDlaNutritionInput,
    CreateOrUpdateDlaPlacesInput,
    DlaContactDto,
    DlaContactServiceProxy,
    DlaDaycareDto,
    DlaDaycareServiceProxy,
    DlaNutritionDto,
    DlaNutritionServiceProxy,
    DlaPlacesDto,
    DlaPlacesServiceProxy,
    DlaOpeningHoursDto,
    DlaOpeningHoursServiceProxy,
    DlaPersonServiceProxy,
    CreateOrUpdateDlaPersonInput,
    DlaPersonDto,
    CreateOrUpdateDlaPersonEmploymentInput,
    DlaPersonEmploymentServiceProxy,
    DlaPersonEmploymentDto,
    DlaPersonDocumentServiceProxy,
    DlaPersonDocumentsOutput,
    GeneralFileInfoDto,
    GeneralFileDto,
    DlaDocumentServiceProxy,
    GeneralDocumentGroupDto,
    DlaDocumentsOutput,
    DlaSponsorshipServiceProxy,
    DlaSponsorshipOutputDto,
    CheckAhvInput,
    ApplicationUploadFilesInput
} from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { ApplicationService } from '../application/application.service';
import { CodeService } from '../common/code/code.service';

@Injectable()
export class DlaService extends ApplicationService {
  dla: DlaDto;

  constructor(
    injector: Injector,
    appService: ApplicationServiceProxy,
    codeService: CodeService,
    private dlaOpeningHoursService: DlaOpeningHoursServiceProxy,
    private dlaDaycareService: DlaDaycareServiceProxy,
    private dlaContactService: DlaContactServiceProxy,
    private dlaPlacesService: DlaPlacesServiceProxy,
    private dlaNutritionService: DlaNutritionServiceProxy,
    private dlaPersonService: DlaPersonServiceProxy,
    private dlaPersonEmploymentService: DlaPersonEmploymentServiceProxy,
    private dlaPersonDocumentService: DlaPersonDocumentServiceProxy,
    private dlaDocumentService: DlaDocumentServiceProxy,
    private dlaSponsorshipService: DlaSponsorshipServiceProxy,
    private dlaService: DlaServiceProxy,
    private fileDownloadService: FileDownloadService
  ) {
    super(injector, codeService, appService);
  }

  get caseId(): string {
    return this.dla.application.caseId;
  }

  get daycareLicence(): DlaDto {
    return this.dla;
  }

  getApplications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
    return this.dlaService.getDlas(filter, sorting, maxResultCount, skipCount);
  }

  createDla(sponsorshipId: number): Observable<string> {
    return this.dlaService.createDla(sponsorshipId).pipe(tap((output: string) => {
      this.dla = null;
    }));
  }

  getDla(caseId: string): Observable<GetDlaOutput> {
    this.dla = null;
    return this.getApplication(caseId).pipe(switchMap(() => {
      return this.dlaService.getDla(caseId).pipe(tap(
        (output: GetDlaOutput) => {
          this.dla = output.dla;
        }));
    }));
  }

  getDaycare(): Observable<DlaDaycareDto> {
    return of(this.dla.dlaDaycare).pipe(delay(0));
  }

  createOrUpdateDlaDaycare(input: CreateOrUpdateDlaDaycareInput): Observable<void> {
      this.setStepToReview(DlaStatus.Daycare);
    return this.dlaDaycareService.createOrUpdateDlaDaycare(input).pipe(tap((status: DlaStatus) => {
      this.dla.dlaDaycare = input.dlaDaycare;
      this.dla.currentStep = status;
    })).pipe(map((status: DlaStatus) => null));
  }

  getContact(): Observable<DlaContactDto> {
    return of(this.dla.dlaContact).pipe(delay(0));
  }

  createOrUpdateDlaContact(input: CreateOrUpdateDlaContactInput): Observable<void> {
      this.setStepToReview(DlaStatus.Contact);
    return this.dlaContactService.createOrUpdateDlaContact(input).pipe(tap((status: DlaStatus) => {
      this.dla.dlaContact = input.dlaContact;
      this.dla.currentStep = status;
    })).pipe(map((status: DlaStatus) => null));
  }

  getPlaces(): Observable<DlaPlacesDto> {
    return of(this.dla.dlaPlaces).pipe(delay(0));
  }

  createOrUpdateDlaPlaces(input: CreateOrUpdateDlaPlacesInput): Observable<void> {
      this.setStepToReview(DlaStatus.Places);
    return this.dlaPlacesService.createOrUpdateDlaPlaces(input).pipe(tap((status: DlaStatus) => {
      this.dla.dlaPlaces = input.dlaPlaces;
      this.dla.currentStep = status;
    })).pipe(map((status: DlaStatus) => null));
  }

  createOrUpdateDlaPerson(input: CreateOrUpdateDlaPersonInput): Observable<number> {
    return this.dlaPersonService.createOrUpdateDlaPerson(input).pipe(tap((dlaPersonId: number) => {
      input.dlaPerson.id = dlaPersonId;
      const oldIndex = this.dla.dlaPeople.findIndex(p => p.id == dlaPersonId);
      const tempEmployments = this.dla.dlaPeople.find(p => p.id == dlaPersonId)?.employments;
      const tempDocuments = this.dla.dlaPeople.find(p => p.id == dlaPersonId)?.documents;
      if (oldIndex != -1) {
        input.dlaPerson.employments = tempEmployments;
        input.dlaPerson.documents = tempDocuments;
        this.dla.dlaPeople[oldIndex] = input.dlaPerson;
      } else {
        input.dlaPerson.employments = [];
        input.dlaPerson.documents = [];
        this.dla.dlaPeople.push(input.dlaPerson);
      }
    })).pipe(map((dlaPersonId: number) => dlaPersonId));
  }

  getEmployments(personId: number): Observable<DlaPersonEmploymentDto[]> {
    let employments = this.dla.dlaPeople.find(p => p.id == personId)?.employments;
    return of(employments).pipe(delay(0));
  }

  createOrUpdateDlaPersonEmployment(input: CreateOrUpdateDlaPersonEmploymentInput): Observable<DlaPersonEmploymentDto[]> {
    return this.dlaPersonEmploymentService.createOrUpdateDlaPersonEmployment(input).pipe(tap((employments: DlaPersonEmploymentDto[]) => {
      const person = this.dla.dlaPeople.find(p => p.id == input.personId);
      person.employments = employments;
    }));
  }

  deletePersonEmployment(personId: number, employmentId: number): Observable<void> {
    return this.dlaPersonEmploymentService.deleteDlaPersonEmployment(this.caseId, employmentId).pipe(tap(() => {
      const person = this.dla.dlaPeople.find(p => p.id == personId);
      const index = person.employments.findIndex(e => e.id == employmentId);
      person.employments.splice(index, 1);
    }));
  }

  getPerson(personId: number): Observable<DlaPersonDto> {
    const person = this.dla.dlaPeople.find(p => p.id == personId);
    return of(person).pipe(delay(0));
  }


  getPeople(forceRefresh?: boolean, caseId = this._caseId): Observable<DlaPersonDto[]> {
    if (forceRefresh) {
      return this.dlaPersonService.getDlaPeople(caseId).pipe(tap((output: DlaPersonDto[]) => {
        this.dla.dlaPeople = output;
      })).pipe(map((output: DlaPersonDto[]) => output));
    }
    return of(this.dla.dlaPeople).pipe(delay(0));
  }


  getOpeningHours(): Observable<DlaOpeningHoursDto[]> {
    return this.dlaOpeningHoursService.getOpeningHours(this.caseId);
  }

  createOrUpdateOpeningHours(dto: DlaOpeningHoursDto[]): Observable<void> {
      this.setStepToReview(DlaStatus.OpeningHours);
    return this.dlaOpeningHoursService.putOpeningHours(this.caseId, dto);
  }

  getNutrition(): Observable<DlaNutritionDto> {
    return of(this.dla.dlaNutrition).pipe(delay(0));
  }

  getDlaSponsorshipData(): Observable<DlaSponsorshipOutputDto> {
    return this.dlaSponsorshipService.getDlaSponsorshipData(this.caseId);
  }

  getDlaSponsorshipFile(id: number): Observable<GeneralFileDto> {
    return this.dlaSponsorshipService.getDlaSponsorshipFile(this.caseId, id);
  }

  CheckRequiredRoles(): Observable<boolean> {
    return this.dlaPersonService.checkRequiredRoles(this.caseId);
  }

  getSelectedRoles(): Observable<string[]> {
      const roles = [];
      const employmentByPerson =  this.dla.dlaPeople.map(x => x.employments);
      const employments = [].concat(...employmentByPerson);
      employments.forEach(x => {
          if (!roles.includes(x.roleId)) {
              roles.push(x.roleId);
          }
      });
    return of(roles);
 }

  createOrUpdateDlaNutrition(input: CreateOrUpdateDlaNutritionInput): Observable<void> {
      this.setStepToReview(DlaStatus.Nutrition);
    return this.dlaNutritionService.createOrUpdateDlaNutrition(input).pipe(tap((status: DlaStatus) => {
      this.dla.dlaNutrition = input.dlaNutrition;
      this.dla.currentStep = status;
    })).pipe(map((status: DlaStatus) => null));
  }

  releaseDla(): Observable<void> {
    return this.dlaService.releaseDla(this.caseId);
  }

  closeApplication(): void {
    this._application = null;
    this._caseId = '';
  }

  deletePerson(personId: number): Observable<void> {
   return this.dlaPersonService.deleteDlaPerson(this.caseId, personId).pipe(tap(() => {
      const index = this.dla.dlaPeople.findIndex(p => p.id == personId);
      this.dla.dlaPeople.splice(index, 1);
    }));
  }

  isPersonValid(personId: number): boolean {
    let isValid = true;
    const person = this.dla.dlaPeople.find(p => p.id == personId);
    isValid &&= (person.employments.length > 0);
    isValid &&= this.areAllMandatoryPersonDocumentsUploaded(personId);
    return isValid;
  }

  hasAnyValidPerson(): boolean {
    for (let i = 0; i < this.dla.dlaPeople.length; i++)  {
      if (this.isPersonValid(this.dla.dlaPeople[i].id)) {
        return true;
      }
    }

    return false;
  }

  checkPeopleCompleteAndValid(): Observable<boolean> {
    return this.dlaPersonService.checkPeopleCompleteAndValid(this.caseId);
  }

  getPersonFile(personId: number, documentId: number): Observable<GeneralFileDto> {
    return this.dlaPersonDocumentService.getFile(this._caseId, personId, documentId);
  }

  deletePersonDocument(personId: number, documentId: number): Observable<void> {
    return this.dlaPersonDocumentService.deleteFile(this._caseId, personId, documentId);
  }

  getPersonDocuments(personId: number, forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
    if (forceRefresh) {
      return this.dlaPersonDocumentService.getDlaPersonFiles(caseId, personId).pipe(tap((output: DlaPersonDocumentsOutput) => {
        const person = this.dla.dlaPeople.find(p => p.id == personId);
        person.documents = output.dlaPersonDocuments;
      })).pipe(map((output: DlaPersonDocumentsOutput) => output.dlaPersonDocuments));
    }
    return of(this.dla.dlaPeople.find(p => p.id == personId)?.documents);
  }

  uploadPersonDocuments(input: ApplicationUploadFilesInput, personId: number): Observable<GeneralFileInfoDto[]> {
    return this.dlaPersonDocumentService.uploadFiles(personId, input);
  }

  getPersonWorkload(personId: number): number {
    const person = this.dla.dlaPeople.find(p => p.id == personId);
    return person.employments.filter(emp => emp.levelOfEmployment != null).reduce((sum, current) => sum + current.levelOfEmployment, 0);
  }

  getTotalWorkload(): number {
    let totalWorkload = 0;
    this.dla.dlaPeople.forEach(p => {
      totalWorkload += p.employments.filter(emp => emp.levelOfEmployment != null).reduce((sum, current) => sum + current.levelOfEmployment, 0);
    });
    return totalWorkload;
  }

  areAllMandatoryPersonDocumentsUploaded(personId: number) {
    const person = this.dla.dlaPeople.find(p => p.id == personId);

    if (person.documents == null) {
      return false;
    }

    for (var i = 0; i < person.documents.length; i++) {
      if (person.documents[i].isMandatory && person.documents[i].files.length === 0) {
        return false;
      }
    }
    return true;
  }

  finishPersonStep(): Observable<number> {
      this.setStepToReview(DlaStatus.Person);
    return this.dlaPersonService.finishPersonStep(this.caseId);
  }

  getFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
    if (forceRefresh) {
      return this.dlaDocumentService.getDlaFiles(caseId).pipe(tap((output: DlaDocumentsOutput) => {
        this.dla.dlaDocuments = output.dlaDocuments;
      })).pipe(map((output: DlaDocumentsOutput) => output.dlaDocuments));
    }
    return of(this.dla.dlaDocuments);
  }

  uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
    return this.dlaDocumentService.uploadFiles(input);
  }

  getFile(id: number): Observable<GeneralFileDto> {
    return this.dlaDocumentService.getFile(this.caseId, id);
  }

  deleteFile(id: number): Observable<void> {
    return this.dlaDocumentService.deleteFile(this.caseId, id);
  }

  areAllMandatoryDocumentsUploaded() {
    if (!this.dla || !this.dla.dlaDocuments){
      return false;
    }

    for (let i = 0; i < this.dla.dlaDocuments.length; i++) {
      if (this.dla.dlaDocuments[i].isMandatory && this.dla.dlaDocuments[i].files.length === 0) {
        return false;
      }
    }
    return true;
  }

  exportToExcel(filter: string, sorting: string): void {
    this.dlaService.exportDlasToExcel(filter, sorting).subscribe(result => {
      this.fileDownloadService.downloadTempFile(result);
    });
  }

  finishDocumentStep(): Observable<number> {
      this.setStepToReview(DlaStatus.Document);
    return this.dlaDocumentService.finishDocumentStep(this.caseId);
  }

  checkIfPersonExists(personId: number): boolean {
    return (this.dla.dlaPeople.find(x => x.id == personId) != null);
  }

    IsPersonAhvDuplicated(input: CheckAhvInput): Observable<boolean> {
      return this.dlaPersonService.isPersonAhvDuplicated(input);
  }

  getPersonIdByAhv(caseId: string, ahv: string): Observable<number> {
      return this.dlaPersonService.getPersonIdByAhv(caseId, ahv);
  }

  getEvaluationComments(): Observable<string> {
    return this.dlaService.getEvaluationComments(this._caseId);
  }

  getNextStepUrl(currentStep: DlaStatus): string {
    const baseUrl = AppConsts.applicantBaseUrl + `/dla/${this.caseId}/wizard/`;
    switch (currentStep) {
      case DlaStatus.Sponsorship:
        return baseUrl + 'daycare';
      case DlaStatus.Daycare:
        return baseUrl + 'contact';
      case DlaStatus.Contact:
        return baseUrl + 'places';
      case DlaStatus.Places:
        return baseUrl + 'opening-hours';
      case DlaStatus.OpeningHours:
        return baseUrl + 'nutrition';
      case DlaStatus.Nutrition:
        return baseUrl + 'person';
      case DlaStatus.Person:
        return baseUrl + 'document';
      case DlaStatus.Document:
        return baseUrl + 'release';
      default:
        return baseUrl + 'daycare';
    }
  }

  getUrlForStep(step: number): string {
    switch (step) {
      case DlaStatus.Sponsorship:
        return 'sponsorship';
      case DlaStatus.Daycare:
        return 'daycare';
      case DlaStatus.Contact:
        return 'contact';
      case DlaStatus.Places:
        return 'places';
      case DlaStatus.OpeningHours:
        return 'opening-hours';
      case DlaStatus.Nutrition:
        return 'nutrition';
      case DlaStatus.Person:
        return 'person';
      case DlaStatus.Document:
        return 'document';
      case DlaStatus.Release:
        return 'release';
      case DlaStatus.OperatingLicence:
          return 'operating-licence';
      default:
        return null;
    }
  }
}

