import { Injectable, Injector } from '@angular/core';
import { ServiceCategory } from '@app/services/service-category.enum';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SelectItemGroup } from 'primeng/api';
import { IdentificationContext } from '@shared/service-proxies/service-proxies';

@Injectable()
export class ServiceCategoryFilteringService extends AppComponentBase {

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.setFromStorage();
    }

    public selectedCategory: ServiceCategory = ServiceCategory.All;

    setServiceCategoryFromRoute(categoryParameter: string) {
        this.setServiceCategory(categoryParameter, true);
    }

    setServiceCategoryByEvent($event: any): void {
        const category = ServiceCategory[$event.value];
        localStorage.setItem('servicesCategory', category);
    }

    getOptions(): SelectItemGroup[] {
        let options = [{
            label: null,
            items: [
                { label: this.l('All'), value: ServiceCategory.All }
            ]
        },
            {
                label: this.l('ServiceCategoryPeoples'),
                items: [
                    { label: this.l('ServiceCategoryPersonLicence'), value: ServiceCategory.PersonLicence },
                ]
            },
        ];

        if (this.permission.isGranted('Pages.Applicant.Services.Dla')) {
            options.push( {
                label: this.l('ServiceCategoryCompanies'),
                items: [
                    { label: this.l('ServiceCategoryDayCareCenter'), value: ServiceCategory.DayCareCenter }
                ]
            });
        }

        return options;
    }

    getIdentificationContext(): IdentificationContext {
        switch (this.selectedCategory) {
            case ServiceCategory.All:
            case ServiceCategory.PersonLicence:
                return IdentificationContext.General;
            case ServiceCategory.DayCareCenter:
                return IdentificationContext.Sponsorship;
        }
    }

    setFromStorage(): void {
        const storageValue = localStorage.getItem('servicesCategory');
        const value = ServiceCategoryFilteringService.parseEnum(storageValue, ServiceCategory);
        if (value != undefined) {
            this.selectedCategory = value;
        }
    }

    private setServiceCategory(categoryParameter: string, saveInLocalStorage: boolean): boolean {
        // https://stackoverflow.com/a/56706510/463890
        let category = ServiceCategoryFilteringService.parseEnum(categoryParameter, ServiceCategory);
        // other method, but not case insensitive:
        //let category = ServiceCategory[categoryParameter as keyof typeof ServiceCategory];
        if (category != undefined) {
            this.selectedCategory = category;
            if (saveInLocalStorage) {
                localStorage.setItem('servicesCategory', categoryParameter);
            }
            return true;
        }
        return false;
    }

    private static parseEnum<T>(value: string, enumType: T): T[keyof T] | undefined {
        if (!value) {
            return undefined;
        }

        for (const property in enumType) {
            const enumMember = enumType[property];
            if (typeof enumMember === 'string') {
                if (enumMember.toUpperCase() === value.toUpperCase()) {
                    const key = enumMember as string as keyof typeof enumType;
                    return enumType[key];
                }
            }
        }
        return undefined;
    }
}
