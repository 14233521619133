import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DlaService } from './dla.service';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class DlaStepGuard implements CanActivate {

    constructor(
        private dlaService: DlaService,
        private permissionChecker: PermissionCheckerService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Redirect to correct step based on permission
        const caseId = this.dlaService.caseId;
        const url = AppConsts.applicantBaseUrl + `/dla/${caseId}/wizard/`;
        if (!this.permissionChecker.isGranted('Pages.Authority.Applications.Dla')) {
            this.router.navigate([url + 'daycare']);
        } else {
            this.router.navigate([url + 'sponsorship']);
        }
        return of(true);
    }
}
