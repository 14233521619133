import { Injectable } from '@angular/core';
import { CantonSpecificsServiceProxy, GetCantonSpecificsOutput, CantonSpecificsDto } from '@shared/service-proxies/service-proxies';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';


@Injectable()
export class CantonSpecificsService {

    private cantonSpecifics: CantonSpecificsDto;

    constructor(
        private cantonSpecificsService: CantonSpecificsServiceProxy) {
    }

    getCantonSpecifics(): Observable<CantonSpecificsDto> {
        if (this.cantonSpecifics) {
            return of(this.cantonSpecifics);
        }

        return this.cantonSpecificsService.get().pipe(map((output: GetCantonSpecificsOutput) => output.cantonSpecifics)).pipe(tap((cantonSpecifics: CantonSpecificsDto) => {
            this.cantonSpecifics = cantonSpecifics;
        }));
    }

    getCantonRequiredLanguageSkills(): Observable<string[]> {
        return this.cantonSpecificsService.getCantonRequiredLanguageSkills();
    }

    currentCantonId(): string {
        return this.cantonSpecifics.cantonId;
    }

    needPostgraduatePractice(postgraduateTitle: string): boolean {
        return this.cantonSpecifics.postgraduateTitlesNotNeedingPractice.find(t => t === postgraduateTitle) === undefined;
    }

    get hasEmploymentType(): boolean {
        return this.cantonSpecifics.hasEmploymentType;
    }
}
