import { Component, Injector, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common'

import { AppComponentBase } from '@shared/common/app-component-base';
import { filter, map, switchMap, tap, take } from 'rxjs/operators';

export class BreadcrumbItem {
    text: string;
    routerLink?: string;
    navigationExtras?: NavigationExtras;

    constructor(text: string, routerLink?: string, navigationExtras?: NavigationExtras) {
        this.text = text;
        this.routerLink = routerLink;
        this.navigationExtras = navigationExtras;
    }

    isLink(): boolean {
        return !!this.routerLink;
    }
}

@Component({
    selector: 'sub-header',
    templateUrl: './sub-header.component.html'
})
export class SubHeaderComponent extends AppComponentBase implements OnInit {

    @Input()  mobileMenuOpen: boolean;
    @Input()  link = '';
    @Output() mobileMenuOpenChange = new EventEmitter<boolean>();
    @Input() customLink: string;

    @Input() title: string;
    @Input() icon: string;
    @Input() description: string;
    @Input() breadcrumbs: BreadcrumbItem[];

    subHeaderLink = '';

    constructor(
        private _router: Router,
        private location: Location,
        injector: Injector
    ) {
        super(injector);

        this.router.events.pipe(
                filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0) )
            .subscribe((event: ActivationEnd) => {
                 this.subHeaderLink = event.snapshot.data['subHeaderLink'];
            });
    }

    ngOnInit(): void {
        let data = null;
        let route = this.route.snapshot;

        while (route) {
            data = route.data || data;
            route = route.firstChild;
        }

        if (data) {
            this.subHeaderLink = data['subHeaderLink'];
        }
    }

    toggleMobileMenuOpen(): void {
        this.mobileMenuOpen = !this.mobileMenuOpen;
        this.mobileMenuOpenChange.emit(this.mobileMenuOpen);
    }

    goToLink(link: string){
        this._router.navigate([link], {relativeTo: this.route});
    }

    goToBreadcrumb(breadcrumb: BreadcrumbItem): void {
        if (!breadcrumb.routerLink) {
            return;
        }

        if (breadcrumb.navigationExtras) {
            this._router.navigate([breadcrumb.routerLink], breadcrumb.navigationExtras);
        } else {
            this._router.navigate([breadcrumb.routerLink]);
        }
    }

    navigate(subHeaderLink: string) {
        if (this.customLink) {
            this._router.navigate([this.customLink]);
            return;
        }

        if (subHeaderLink) {
            if (subHeaderLink === 'back') {
                this.location.back();
                return;
            }

            this._router.navigate([subHeaderLink], {relativeTo: this.route});
        }
    }
}
