import { Injectable } from '@angular/core';
import { Observable, from, map, of } from 'rxjs';
import Swal from 'sweetalert2';

export interface IStreetWithNumber {
    street: string | undefined;
    streetNr: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class CheckMissingStreetNumberService {
    constructor() {}

    public check(dto: IStreetWithNumber, displayDialog = true): Observable<boolean> {
        if (this.IsMissing(dto)) {
            if (!displayDialog) {
                return of(false);
            }

            return this.displayDialog();
        }

        return of(true);
    }

    public checkArray(dtoArray: IStreetWithNumber[], displayDialog = true): Observable<boolean> {
        for (const element of dtoArray) {
            if (this.IsMissing(element)) {
                if (!displayDialog) {
                    return of(false);
                }

                return this.displayDialog();
            }
        }

        return of(true);
    }

    private IsMissing(dto: IStreetWithNumber): boolean {
        const streetFilledOut = this.isNonEmptyString(dto.street);
        const streetNrFilledOut = this.isNonEmptyString(dto.streetNr);

        return streetFilledOut && !streetNrFilledOut;
    }

    private isNonEmptyString(text: string): boolean {
        return typeof text === "string" && text.length > 0;
    }

    private displayDialog(): Observable<boolean> {
        let observable = from(
            Swal.fire({
                title: abp.localization.localize('AreYouSure', 'Sirona'),
                text: abp.localization.localize('ConfirmMissingStreetNumber', 'Sirona'),
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: abp.localization.localize('Yes', 'Sirona'),
                cancelButtonText: abp.localization.localize('No', 'Sirona'),
            })
        );

        return observable.pipe(map((x) => x.isConfirmed));
    }
}
