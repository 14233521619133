import { Injectable } from '@angular/core';
import { ICode } from '@app/shared/common/code/code.interface';
import { CodeServiceProxy, CodeCategory, CodeSubcategory, CodeListDto } from '@shared/service-proxies/service-proxies';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { Observable, Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

@Injectable()
export class CodeService {
    private halfMax = Number.MAX_VALUE / 2;
    allCodes: CodeListDto[] = [];

    constructor(
        private localization: AppLocalizationService,
        private codeService: CodeServiceProxy) {
    }

    sortCode = (a: ICode, b: ICode) => {
        if (a.listIndex === b.listIndex){
            return a.translation.localeCompare(b.translation)
        }

        if ((a.listIndex == undefined ? this.halfMax : a.listIndex < 0 ? this.halfMax - a.listIndex : a.listIndex) <
                (b.listIndex == undefined ? this.halfMax : b.listIndex < 0 ? this.halfMax - b.listIndex : b.listIndex)) {
            return -1
        } else {
            return 1;
        }
    };

    // Get all codes synchronously
    loadCodes(): Observable<CodeListDto[]> {
        return this.codeService.getAllCodes().pipe(tap((codes) => {
            this.allCodes = codes;
        }));
    }

    getCodes(category: CodeCategory, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<ICode>> {
        const subject = new Subject<Array<ICode>>();
        this.codeService.getCodes(category, '', codeSubcategory, '', AppConsts.grid.maxPageSize, 0).pipe(first()).subscribe((result) => {
            var codes = result.items.map(x => Object.assign({ translation: this.localization.l(x.id) } as ICode, x)).sort(this.sortCode);
            subject.next(codes);
            subject.complete();
        });

        return subject.asObservable();
    }

    getCodesSync(category: CodeCategory): Array<ICode> {
        return this.allCodes.filter(c => c.codeCategory === category).map(x => Object.assign({ translation: this.localization.l(x.id) } as ICode, x)).sort(this.sortCode);
    }

    getCodesForDropdown(category: CodeCategory, allOnTop?: boolean, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<SelectItem>> {
        const subject = new Subject<Array<SelectItem>>();
        this.getCodes(category, codeSubcategory).pipe(first()).subscribe((result) => {
            const codes = result.map(x => ({ value: x.id, label: x.translation, disabled: false } as SelectItem));
            if (allOnTop) {
                codes.unshift({ value: null, label: this.localization.l('All') });
            }

            subject.next(codes);
            subject.complete();
        });

        return subject.asObservable();
    }

    getActiveCodesForDropdown(category: CodeCategory, currentValue: string, allOnTop?: boolean, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<SelectItem>> {
        const subject = new Subject<Array<SelectItem>>();
        this.getCodes(category, codeSubcategory).pipe(first()).subscribe((result) => {

            // Set inactive codes disabled to prevent user from updating as long as this code is set.
            //const codes = result.filter(c => c.isActive || c.id == currentValue).map(x => ({ value: x.id, label: x.translation, disabled: !x.isActive } as SelectItem));
            const codes = result.filter(c => c.isActive || c.id == currentValue).map(x => ({ value: x.id, label: x.translation, disabled: false } as SelectItem));

            if (allOnTop) {
                codes.unshift({ value: null, label: this.localization.l('All') });
            }

            subject.next(codes);
            subject.complete();
        });

        return subject.asObservable();
    }

    getActiveCodesForDropdownSync(category: CodeCategory, currentValue: string, allOnTop?: boolean): Array<SelectItem> {
        let codes = this.getCodesSync(category).filter(c => c.isActive || c.id == currentValue).map(x => ({ value: x.id, label: x.translation, disabled: false } as SelectItem));

        if (allOnTop) {
            codes.unshift({ value: null, label: this.localization.l('All') });
        }

        return codes;
    }

    getEnumForDropdown(enumName: string, enumObj: object, allOnTop?: boolean): Array<SelectItem> {
        const array = new Array<SelectItem>();
        if (allOnTop) {
            array.push({ value: null, label: this.localization.l('All') });
        }
        Object.keys(enumObj).filter(x => isNaN(+x)).forEach(x => {
            array.push({ value: enumObj[x], label: this.localization.l(`${enumName}_${x}`) });
        });
        return array;
    }

    getTrueFalseDropdown(): Array<SelectItem> {
        return [
            { value: true, label: this.localization.l('Yes') },
            { value: false, label: this.localization.l('No') },
        ]
    }
}
