import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DataTransferService {

    private data = new BehaviorSubject<any>([]);
    cast = this.data.asObservable();

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();

    sendData(data) {
        this.data.next(data);
    }

    emitChange() {
        this.emitChangeSource.next(true);
    }

}
