import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class SavedRouteGuard implements CanActivate {

    constructor(
        private _router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const routeUrl = localStorage.getItem('afterLoginUrl');
        if (routeUrl != null) {
            localStorage.removeItem('afterLoginUrl');
            this._router.navigate([routeUrl]);
            return of(false);
        }

        return of(true);
    }
}
