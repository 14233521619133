import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceCategoryFilteringService } from './service-category-filtering.service';
import { UserService } from './user.service';

@Injectable()
export class FirstLoginRouteGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router,
        private serviceCategoryFilteringService: ServiceCategoryFilteringService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const serviceCategory = route.paramMap.get('serviceCategory');
        this.serviceCategoryFilteringService.setServiceCategoryFromRoute(serviceCategory);

        if (abp.auth.isGranted('Pages.Authority')) {
            let tree = this.router.parseUrl(AppConsts.authorityStartUrl);
            tree.queryParams = route?.queryParams;
            return tree;
        }

        return this.userService.hasAnyApplicationOrSponsorship().pipe(
            map(result => {
                if (!result) {
                    let tree = this.router.parseUrl(`app/services`);
                    tree.queryParams = route?.queryParams;
                    return tree;
                } else {
                    let tree = this.router.parseUrl(AppConsts.applicantStartUrl);
                    tree.queryParams = route?.queryParams;
                    return tree;
                }
            })
        );
    }
}
