import { PermissionCheckerService } from 'abp-ng2-module';
import { ConfigServiceProxy } from './../../../shared/service-proxies/service-proxies';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class DlaMaintenanceGuard implements CanActivate {

    constructor(
        private configService: ConfigServiceProxy,
        private router: Router,
        private permissionChecker: PermissionCheckerService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
       return this.configService.getMaintenanceConfiguration().pipe(map(conf => {
        const isAuthority = this.permissionChecker.isGranted('Pages.Authority.Applications.Dla');
        const restrictionDate = new Date(conf.disableDlaServiceUntil?.toString());
        const now = new Date();
        if ((restrictionDate.getTime() > now.getTime() && !isAuthority) || (restrictionDate.getTime() > now.getTime() && conf.disableDlaForAuthority)) {
            this.router.navigate([AppConsts.applicantBaseUrl + `/maintenance`]);
            return false
        } else {
            return true;
        }
       }));
    }
}
