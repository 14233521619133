import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VisibilityService } from '@app/shared/common/navigation/visibility.service';
import { first } from 'rxjs/operators';
import { StatusVisibility, TabVisibility, GetVisibilityOutput } from '@shared/service-proxies/service-proxies';

@Injectable()
export class WizardNavService {

    constructor(
        private router: Router,
        private visibilityService: VisibilityService) {
    }

    isRedirect(route: ActivatedRoute): boolean {
        return route.firstChild == null || route.firstChild.component == null;
    }

    redirect(to: (s: GetVisibilityOutput) => StatusVisibility, wizardRoute: ActivatedRoute): void {
        this.visibilityService.changes().pipe(first()).subscribe(x => {
            const url = this.getUrlForStatus(wizardRoute, to.call(this, x));
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([url]);
            });
        });
    }

    toFirst(visibilities: GetVisibilityOutput): StatusVisibility {
        return visibilities.items.find(x => x.visibility > TabVisibility.NonNavigable); // first which is not NonNavigable
    }

    toLast(visibilities: GetVisibilityOutput): StatusVisibility {
        const sorted = visibilities.items.slice().sort((a, b) => // order by visibility desc, then by status desc
            a.visibility !== b.visibility ? b.visibility - a.visibility : b.status - a.status);
        return sorted[0];
    }

    toCurrent(visibilities: GetVisibilityOutput): StatusVisibility {
        return visibilities.items.find(x => x.status === visibilities.currentStatus);
    }

    toNext(visibilities: GetVisibilityOutput): StatusVisibility {
        const current = this.toCurrent(visibilities);
        const indexCurrent = visibilities.items.indexOf(current);
        const indexLast = visibilities.items.length - 1;
        return visibilities.items[indexCurrent === indexLast ? indexLast : indexCurrent + 1];
    }

    private getUrlForStatus(wizardRoute: ActivatedRoute, statusVisibility: StatusVisibility): string {
        const route = wizardRoute.snapshot.routeConfig.children.find(x => x.data && x.data['wizard'] && x.data['wizard']['status'] === statusVisibility.status);
        if (route) {
            const removeRedirect = this.isRedirect(wizardRoute) && wizardRoute.firstChild != null;
            const baseUrl = removeRedirect ? this.router.url.substring(0, this.router.url.lastIndexOf('/')) : this.router.url;
            return baseUrl + '/' + route.path;
        } else {
            return null;
        }
    }
}
