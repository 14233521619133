import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ReplaySubject, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { TabVisibility } from '@shared/service-proxies/service-proxies';
import { VisibilityService } from '../navigation/visibility.service';
import { AppRouteGuard } from '../auth/auth-route-guard';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ApplicationService } from '@app/shared/application/application.service';

@Injectable()
export class WizardGuard implements CanActivate, CanActivateChild, CanDeactivate<AppComponentBase> {

    constructor(
        private router: Router,
        private appRouteGuard: AppRouteGuard,
        private applicationService: ApplicationService,
        private visibilityService: VisibilityService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.visibilityService.update();
        return of(true); // always activate, method only used for wizard initialization
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!route.data['wizard'] || route.component == null) {
            // always activate non-wizard child or redirect
            return of(true);
        }

        const visibilityObservable = new ReplaySubject<any>(1);
        this.visibilityService.update().pipe(first()).subscribe(x => {
            let routeStatus = route.data && route.data['wizard'] && route.data['wizard']['status'];
            let canActivate = routeStatus !== undefined &&
                x.items.some(x => x.status === routeStatus) &&
                x.items.find(x => x.status === routeStatus).visibility > TabVisibility.NonNavigable;
                
            canActivate = true;
            visibilityObservable.next(canActivate);
            visibilityObservable.complete();
            if (!canActivate) {
                this.router.navigate([this.appRouteGuard.selectBestRoute()]);
            }
        }, (error) => {
            visibilityObservable.next(false);
            visibilityObservable.complete();
            this.router.navigate([this.appRouteGuard.selectBestRoute()]);
        });

        return visibilityObservable;
    }

    canDeactivate(component: AppComponentBase): boolean | Observable<boolean> {
        return true;
    }
}
