import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressAutocompleteServiceProxy, KeyValuePairOfStringString } from '@shared/service-proxies/service-proxies';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AddressAutoCompleteService extends AppComponentBase {

    private forms: Array<{
        postCode: string;
        cities: KeyValuePairOfStringString[];
        citySuggestions: string[];
        streets: KeyValuePairOfStringString[];
        streetSuggestions: string[];
        houseNumbers: KeyValuePairOfStringString[];
        houseNumberSuggestions: string[];
        formReference: FormGroup;
    }> = [];

    constructor(
        private addressAutocompleteServiceProxy: AddressAutocompleteServiceProxy,
        injector: Injector
    ) {
        super(injector);
    }

    private getFormData(index: number = 0) {
        if (!this.forms[index]) {
            this.forms[index] = {
                postCode: null,
                cities: [],
                citySuggestions: [],
                streets: [],
                streetSuggestions: [],
                houseNumbers: [],
                houseNumberSuggestions: [],
                formReference: null
            };
        }
        return this.forms[index];
    }

    setMainFormReference(form: FormGroup, index: number = 0) {
        this.getFormData(index).formReference = form;
    }

    getMainFormReference(index: number = 0): FormGroup {
        return this.getFormData(index).formReference;
    }

    clearCitySuggestions(index: number = 0) {
        this.getFormData(index).citySuggestions = [];
    }

    clearStreetSuggestions(index: number = 0) {
        this.getFormData(index).streetSuggestions = [];
    }

    clearHouseNumberSuggestions(index: number = 0) {
        this.getFormData(index).houseNumberSuggestions = [];
    }

    public getCitySuggestions(index: number = 0): string[] {
        return this.getFormData(index).citySuggestions;
    }

    public getStreetSuggestions(index: number = 0): string[] {
        return this.getFormData(index).streetSuggestions;
    }

    public getHouseNumberSuggestions(index: number = 0): string[] {
        return this.getFormData(index).houseNumberSuggestions;
    }

    searchCities(postCode: string, index: number = 0, groupName: string = undefined, formArrayIndex: number = 0) {
        const formData = this.getFormData(index);
        if (postCode && postCode.length === 4 && this.isCountrySwitzerland(index, groupName, formArrayIndex)) {
            this.addressAutocompleteServiceProxy.getCitiesByPostCode(postCode).subscribe(cities => {
                formData.citySuggestions = cities.map((city: KeyValuePairOfStringString) => city.value);
                formData.cities = cities;
                formData.postCode = postCode;
            });
        } else {
            formData.citySuggestions = [];
        }
    }

    loadCitiesFromExistingData(postCode: string, city: string, street: string, streetNr: string, index: number = 0) {
        const formData = this.getFormData(index);
        if (postCode.length === 4) {
            this.addressAutocompleteServiceProxy.getCitiesByPostCode(postCode).subscribe(cities => {
                formData.citySuggestions = cities.map((city: KeyValuePairOfStringString) => city.value);
                formData.cities = cities;
                formData.postCode = postCode;

                formData.formReference.get('postCode').setValue(postCode);
                formData.formReference.get('city').setValue(city);

                let cityId = Number(formData.cities.find(c => c.value === city)?.key);
                if (isNaN(cityId)) {
                    return;
                }
                this.addressAutocompleteServiceProxy.getStreetsByCity(cityId, street).subscribe((streets: KeyValuePairOfStringString[]) => {
                    formData.streets = streets;
                    formData.streetSuggestions = streets?.map(x => x.value);
                    formData.formReference.get('street').setValue(street);
                });

                if (streetNr != null) {
                    formData.houseNumberSuggestions = [streetNr];
                    formData.formReference.get('streetNr').setValue(streetNr);
                }
            });
        }
    }

    onCitySelected(city: string, index: number = 0) {
        const formData = this.getFormData(index);
        formData.formReference.get('postCode').setValue(formData.postCode);
        formData.formReference.get('city').setValue(city);
        formData.postCode = null;
        formData.citySuggestions = [];
    }

    onCitySelectedKeepingSuggestions(city: string, index: number = 0) {
        const formData = this.getFormData(index);
        formData.formReference.get('postCode').setValue(formData.postCode);
        formData.formReference.get('city').setValue(city);
        formData.postCode = null;
    }

    onCitySelectedForIndex(city: string, index: number, groupName: string, formArrayIndex: number = 0) {
        const formData = this.getFormData(index);
        const previousEmployments = formData.formReference.get(groupName) as FormArray;
        const previousemployment = previousEmployments.at(formArrayIndex) as FormGroup;
        previousemployment.get('postCode').setValue(formData.postCode);
        previousemployment.get('city').setValue(city);
        formData.postCode = null;
        formData.citySuggestions = [];
    }

    searchStreets(query: string, index: number = 0, groupName: string = undefined, formArrayIndex: number = 0) {
        const formData = this.getFormData(index);
        let city = '';
        if (groupName != undefined) {
            const previousEmployments = formData.formReference.get(groupName) as FormArray;
            const previousemployment = previousEmployments.at(formArrayIndex) as FormGroup;
            city = previousemployment.get('city').value;
        } else {
            city = formData.formReference.get('city').value;
        }

        let cityId = Number(formData.cities.find(c => c.value === city)?.key);
        if (isNaN(cityId)) {
            formData.streets = [];
            formData.streetSuggestions = [];
            return;
        }

        this.addressAutocompleteServiceProxy.getStreetsByCity(cityId, query).subscribe((streets: KeyValuePairOfStringString[]) => {
            formData.streets = streets;
            formData.streetSuggestions = streets.map(x => x.value);
        });
    }

    searchStreetsByCity(city: string, query: string = 'A', index: number = 0): void {
        const formData = this.getFormData(index);
        let cityId = Number(formData.cities.find(c => c.value === city)?.key);
        this.addressAutocompleteServiceProxy.getStreetsByCity(cityId, query).subscribe((streets: KeyValuePairOfStringString[]) => {
            formData.streets = streets;
            formData.streetSuggestions = streets?.map(x => x.value);
        });
    }

    onStreetSelected(selection: string, index: number = 0) {
        const formData = this.getFormData(index);
    }

    searchHouseNumbers(query: string = '1', index: number = 0, groupName: string = undefined, formArrayIndex: number = 0) {
        const formData = this.getFormData(index);
        let street = '';
        if (groupName != undefined) {
            const previousEmployments = formData.formReference.get(groupName) as FormArray;
            const previousemployment = previousEmployments.at(formArrayIndex) as FormGroup;
            street = previousemployment.get('street').value;
        } else {
            street = formData.formReference.get('street').value;
        }

        let streetId = Number(formData.streets.find(str => str.value === street)?.key);
        if (isNaN(streetId)) {
            formData.houseNumbers = [];
            formData.houseNumberSuggestions = [];
            return;
        }

        this.addressAutocompleteServiceProxy.getHouseNumbersByStreetId(streetId, query).subscribe((houseNumbers: KeyValuePairOfStringString[]) => {
            formData.houseNumbers = houseNumbers;
            formData.houseNumberSuggestions = houseNumbers.map(x => x.value);
        });
    }

    searchHouseNumbersByStreet(street: string, query: string = '1', index: number = 0, groupName: string = undefined) {
        const formData = this.getFormData(index);
        let streetId = Number(formData.streets.find(str => str.value === street)?.key);
        if (isNaN(streetId)) {
            formData.houseNumbers = [];
            formData.houseNumberSuggestions = [];
            return;
        }

        this.addressAutocompleteServiceProxy.getHouseNumbersByStreetId(streetId, query).subscribe((houseNumbers: KeyValuePairOfStringString[]) => {
            formData.houseNumbers = houseNumbers;
            formData.houseNumberSuggestions = houseNumbers.map(x => x.value);
        });
    }

    onHouseNumberSelected(selection: KeyValuePairOfStringString, index: number = 0) {
        const formData = this.getFormData(index);
    }

    isCountrySwitzerland(index: number = 0, groupName: string = undefined, formArrayIndex: number = 0): boolean {
        const formData = this.getFormData(index);
        let countryControl: AbstractControl;
        if (groupName != undefined) {
            const previousEmployments = formData.formReference.get(groupName) as FormArray;
            const previousemployment = previousEmployments.at(formArrayIndex) as FormGroup;
            countryControl = previousemployment.get('countryId');
        } else {
            countryControl = formData.formReference.get('countryId');
        }

        let isCountryCh = true;
        if (countryControl != null) // The country field is not required
        {
            isCountryCh = countryControl.value === AppConsts.codes.country.ch;
        }

        return isCountryCh;
    }
}
