import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CantonSpecificsDto } from '@shared/service-proxies/service-proxies';
import { CantonSpecificsService } from '@app/shared/canton-specifics/canton-specifics.service';

@Injectable()
export class CantonSpecificsResolver implements Resolve<CantonSpecificsDto | string> {

    constructor(private cantonSpecificsService: CantonSpecificsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CantonSpecificsDto | string> {
        return this.cantonSpecificsService.getCantonSpecifics()
            .pipe(
                catchError(err => of(err))
            );
    }
}
