import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { ServiceCategoryFilteringService } from '@app/shared/services/service-category-filtering.service';

@Injectable()
export class CategoryFilterGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _serviceCategoryFilteringService: ServiceCategoryFilteringService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const queryParams = route?.queryParams;

        if (queryParams.category !== undefined) {
            this._serviceCategoryFilteringService.setServiceCategoryFromRoute(queryParams.category);
        }
        return true;
    }

}
