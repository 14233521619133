import { Injectable } from '@angular/core';
import { CreateLiaStatusWizardStepInput,
    CreateOrUpdateProfessionRequirementInput,
    LiaStatusWizardStepDto,
    LiaStatusWizardStepServiceProxy,
    ProfessionRegisterAffiliationDto,
    ProfessionRegisterAffiliationServiceProxy,
    ProfessionRequirementAlternativeDto,
    ProfessionRequirementDto,
    ProfessionRequirementGroupDto,
    ProfessionRequirementGroupServiceProxy,
    ProfessionRequirementServiceProxy,
    ProfessionRequirementType,
    UpdateLiaStatusWizardStepsInput,
    CreateEhcStatusWizardStepInput,
    EhcStatusWizardStepDto,
    EhcStatusWizardStepServiceProxy,
    UpdateEhcStatusWizardStepsInput,
    EhcPracticeConfigurationServiceProxy,
    CreateOrUpdateEhcPracticeConfigurationInput,
    EhcPracticeConfigurationDto,
    ApplicationType,
    ConfigExport} from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfessionConfigurationService {

  constructor(private professionRequirementProxy: ProfessionRequirementServiceProxy,
              private professionRegisterAffiliationProxy: ProfessionRegisterAffiliationServiceProxy,
              private professionRequirementGroupProxy: ProfessionRequirementGroupServiceProxy,
              private liaStatusWizardStepProxy: LiaStatusWizardStepServiceProxy,
              private ehcStatusWizardStepProxy: EhcStatusWizardStepServiceProxy,
              private ehcPracticeConfigurationServiceProxy: EhcPracticeConfigurationServiceProxy) {  }


  exportGlobalProfessionConfiguration(): Observable<ConfigExport> {
    return this.professionRegisterAffiliationProxy.exportGlobalProfessionConfiguration();
  }

  importGlobalProfessionConfiguration(input: ProfessionRegisterAffiliationDto[]): Observable<void> {
    return this.professionRegisterAffiliationProxy.importGlobalProfessionConfiguration(input);
    }

  getAffiliations(): Observable<ProfessionRegisterAffiliationDto[]> {
    return this.professionRegisterAffiliationProxy.getAll();
  }

  getAffiliationById(professionId: string): Observable<ProfessionRegisterAffiliationDto> {
    return this.professionRegisterAffiliationProxy.get(professionId);
  }

  updateAffiliation(professionAffiliationDto: ProfessionRegisterAffiliationDto): Observable<void> {
    return this.professionRegisterAffiliationProxy.update(professionAffiliationDto);
  }

  getRequirement(id: number): Observable<ProfessionRequirementDto> {
    return this.professionRequirementProxy.get(id);
  }

  getRequirementByProfessionAndType(professionId: string, requirementTypeId: ProfessionRequirementType): Observable<ProfessionRequirementDto | undefined> {
    return this.professionRequirementProxy.getRequirementByProfessionAndType(professionId, requirementTypeId);
  }

  createOrUpdateRequirement(professionDto: CreateOrUpdateProfessionRequirementInput): Observable<void> {
    return this.professionRequirementProxy.createOrUpdate(professionDto);
  }

  deleteRequirement(id: number): Observable<void> {
    return this.professionRequirementProxy.delete(id);
  }

  getAlternative(alternativeId: number): Observable<ProfessionRequirementAlternativeDto> {
    return this.professionRequirementGroupProxy.getAlternative(alternativeId);
  }

  createAlternative(groupId: number): Observable<number> {
    return this.professionRequirementGroupProxy.createAlternative(groupId);
  }

  deleteAlternative(alternativeId: number): Observable<void> {
    return this.professionRequirementGroupProxy.deleteAlternative(alternativeId);
  }

  getGroup(groupId: number): Observable<ProfessionRequirementGroupDto> {
    return this.professionRequirementGroupProxy.get(groupId);
  }

  createOrUpdateGroup(group: ProfessionRequirementGroupDto): Observable<void> {
    return this.professionRequirementGroupProxy.createOrUpdate(group);
  }

  deleteGroup(id: number): Observable<void> {
    return this.professionRequirementGroupProxy.delete(id);
  }

  getLiaWizardStepsByProfessionId(professionId: string): Observable<LiaStatusWizardStepDto[]> {
    return this.liaStatusWizardStepProxy.getAllByProfessionId(professionId);
  }

  createLiaWizardStep(input: CreateLiaStatusWizardStepInput): Observable<LiaStatusWizardStepDto> {
    return this.liaStatusWizardStepProxy.create(input);
  }

  updateLiaWizardSteps(input: UpdateLiaStatusWizardStepsInput): Observable<void> {
    return this.liaStatusWizardStepProxy.updateMany(input);
  }

  deleteLiaWizardStep(step: number, professionId: string): Observable<void> {
    return this.liaStatusWizardStepProxy.delete(step, professionId);
  }

  getEhcWizardStepsByProfessionId(professionId: string) : Observable<EhcStatusWizardStepDto[]> {
    return this.ehcStatusWizardStepProxy.getAllByProfessionId(professionId);
  }

  createEhcWizardStep(input: CreateEhcStatusWizardStepInput): Observable<EhcStatusWizardStepDto> {
    return this.ehcStatusWizardStepProxy.create(input);
  }

  updateEhcWizardSteps(input: UpdateEhcStatusWizardStepsInput): Observable<void> {
    return this.ehcStatusWizardStepProxy.updateMany(input);
  }

  deleteEhcWizardStep(step: number, professionId: string): Observable<void> {
    return this.ehcStatusWizardStepProxy.delete(step, professionId);
  }

  getEhcPracticeConfiguration(professionId: string): Observable<EhcPracticeConfigurationDto> {
    return this.ehcPracticeConfigurationServiceProxy.get(professionId);
  }

  createOrUpdateEhcPracticeConfiguration(input: CreateOrUpdateEhcPracticeConfigurationInput): Observable<void> {
    return this.ehcPracticeConfigurationServiceProxy.createOrUpdate(input);
  }

  deleteEhcPracticeConfiguration(professionId: string): Observable<void> {
    return this.ehcPracticeConfigurationServiceProxy.delete(professionId);
    }

}
