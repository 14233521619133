import { Injector, Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

import { DOCUMENT } from '@angular/common';
import { PermissionCheckerService } from 'abp-ng2-module';

@Component({
    templateUrl: './theme7-brand.component.html',
    selector: 'theme7-brand',
    encapsulation: ViewEncapsulation.None
})
export class Theme7BrandComponent extends AppComponentBase implements OnInit {

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-dark-2.svg';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    startUrl: string;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private permissionChecker: PermissionCheckerService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.startUrl = this.permissionChecker.isGranted('Pages.Authority.Applications.Person') ? AppConsts.authorityStartUrl : AppConsts.applicantStartUrl;
    }

    clickTopbarToggle(): void {
        this.document.body.classList.toggle('m-topbar--on');
    }

    clickLeftAsideHideToggle(): void {
        this.document.body.classList.toggle('m-aside-left--hide');
    }
}
