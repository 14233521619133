import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { ServiceCategoryFilteringService } from '@app/shared/services/service-category-filtering.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _refreshTokenService: RefreshTokenService,
        private _serviceCategoryFilteringService: ServiceCategoryFilteringService
    ) { }

    canActivateInternal(data: any, state: RouterStateSnapshot, route: ActivatedRouteSnapshot): Observable<boolean> {
        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }

        const queryParams = route?.queryParams;

        if (!this._sessionService.user) {
            let sessionObservable = new Subject<any>();

            this._refreshTokenService.tryAuthWithRefreshToken()
                .subscribe(
                    (autResult: boolean) => {
                        if (autResult) {
                            sessionObservable.next(true);
                            sessionObservable.complete();
                            location.reload();
                        } else {
                            sessionObservable.next(false);
                            sessionObservable.complete();
                            if (queryParams.autoLogin !== undefined) {
                                let url = state.url.split('?')[0];
                                this.saveRouteForExternalLogin(url);
                            }
                            if (queryParams.category !== undefined) {
                                this.setFilter(queryParams.category);
                            }
                            this._router.navigate(['/account/login'], { queryParams: queryParams, queryParamsHandling: 'merge' });
                        }
                    },
                    (error) => {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        this._router.navigate(['/account/login'], { queryParams: queryParams, queryParamsHandling: 'merge' });
                    }
                );
            return sessionObservable;
        }

        if (!data || !data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()], { queryParams: queryParams, queryParamsHandling: 'merge' });
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state, route);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null, null);
    }

    saveRouteForExternalLogin(route: string): void {
        localStorage.setItem('afterLoginUrl', route);
    }

    setFilter(filter: string): void {
       this._serviceCategoryFilteringService.setServiceCategoryFromRoute(filter);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Authority.Applications.Person')) {
            return AppConsts.authorityBaseUrl + '/applications/peopleApplications';
        }

        if (this._permissionChecker.isGranted('Pages.Authority.Applications.Person')) {
            return AppConsts.authorityBaseUrl + '/applications/ehcs';
        }

        if (this._permissionChecker.isGranted('Pages.Authority.Applications.Dla')) {
            return AppConsts.authorityBaseUrl + '/applications/dlas';
        }

        if (this._permissionChecker.isGranted('Pages.Applicant')) {
            return AppConsts.applicantStartUrl;
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        return '/app/notifications';
    }
}
