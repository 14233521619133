import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable()
export class KeyPressValidationService {

    public allowJustNumeric(event) {
        const charCode = (event.query) ? event.query : event.keyCode;
        return !(charCode > 31
            && (charCode < 48 || charCode > 57));
    }

    public allowJustDecimal(event) {
        const charCode = (event.query) ? event.query : event.keyCode;
        return (charCode === 46 || charCode === 44 || (charCode >= 48 && charCode <= 57));
    }
}

