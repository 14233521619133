<div [class]="currentTheme.baseSettings.subHeader.containerStyle">
    <div [class]="containerClass + ' d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'">
        <div class="d-flex align-items-center flex-wrap mr-2 flex-grow-1">
            <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle" (click)="toggleMobileMenuOpen()">
                <span></span>
            </button>
            <h1 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 1" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h1>
            <h2 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 2" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h2>
            <h3 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 3" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h3>
            <h4 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 4" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h4>
            <h5 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 5" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h5>
            <h6 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 6" [class]="currentTheme.baseSettings.subHeader.titleStyle"><i *ngIf="icon" [class]="icon">&nbsp;</i>{{title}}</h6>
            <div *ngIf="description" class='subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200'></div>
            <div *ngIf="description" class="d-flex align-items-center">
                <span *ngIf="!link" class='text-muted font-weight-bold mr-4'>{{description}}</span>
                <a *ngIf="link" class='text-muted font-weight-bold mr-4 cursor-pointer' (click)=goToLink(link)>{{description}}</a>
            </div>
            <div>
                <ng-content></ng-content>
            </div>
            <div *ngIf="breadcrumbs && breadcrumbs.length > 0" class='subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200'></div>
            <ul *ngIf="breadcrumbs && breadcrumbs.length > 0"
                class='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm'>
                <li class='breadcrumb-item' *ngFor="let breadcrumbItem of breadcrumbs">
                    <a *ngIf="breadcrumbItem.isLink()" class="cursor-pointer" (click)="goToBreadcrumb(breadcrumbItem)" class='text-muted'>
                        {{breadcrumbItem.text}}
                    </a>
                    <span *ngIf="!breadcrumbItem.isLink()" class='text-muted'>{{breadcrumbItem.text}}</span>
                </li>
            </ul>
            <div *ngIf="subHeaderLink" class="d-flex justify-content-end flex-grow-1"> <span class="text-muted"><a routerLink="" (click)="navigate(subHeaderLink)" class="text-light-muted text-hover-secondary cursor-pointer"><i class="fa fa-sm fa-chevron-left"></i> {{l('Back')}}</a></span></div>
        </div>
        <div class="d-flex align-items-center">
            <ng-content select="div[role=actions]"></ng-content>
        </div>
    </div>
</div>
