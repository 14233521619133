import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationService } from '@app/shared/application/application.service';
import { ApplicationDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Injectable()
export class ApplicationGuard implements CanActivate, CanDeactivate<AppComponentBase> {

    constructor(
        private applicationService: ApplicationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.applicationService.getApplication(route.params.caseId)
            .pipe(map((application: ApplicationDto) => true));
    }

    canDeactivate(component: AppComponentBase): boolean | Observable<boolean> {
        this.applicationService.closeApplication();
        return true;
    }
}
