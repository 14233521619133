import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { EhcService } from './ehc.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class EhcStepGuard implements CanActivate {

        constructor(
            private ehcService: EhcService,
            private router: Router
        ) {
        }

        canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
            // Navigate to first step
            const caseId = this.ehcService.caseId;
            const step = this.ehcService.getUrlForStep(this.ehcService.firstStep);

            const url = AppConsts.applicantBaseUrl + `/ehc/${caseId}/wizard/${step}`;
            this.router.navigate([url]);

            return of(true);
        }
}
