import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationService } from '@app/shared/application/application.service';
import { DlaService } from './dla.service';

@Injectable()
export class DlaGuard implements CanActivate {

    constructor(
        private dlaService: DlaService,
        private applicationService: ApplicationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
       return this.dlaService.getDla(this.applicationService.caseId).pipe(map(() => true));
    }
}
