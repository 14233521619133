import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeService } from '@app/shared/common/code/code.service';

@Injectable()
export class CodesGuard implements CanActivate {

    constructor(
        private codeService: CodeService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.codeService.loadCodes().pipe(map(() => true));
    }
}
